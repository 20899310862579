


















































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import QuestionTableItem from "@/components/questionary/QuestionTableItem.vue";
import AddQuestionModal from "@/components/modals/AddQuestionModal.vue";
import BackButton from "@/components/back-button.vue";
import PlusIcon from "@iconify-icons/ion/add-outline";
import {mapActions, mapGetters} from "vuex";
import {VenueData, UserData} from "@/types";

export default Vue.extend({
  name: "questionary-questions" as string,

  components: {
    Layout,
    SearchBar,
    "question-table-item": QuestionTableItem,
    "add-question-modal": AddQuestionModal,
    "back-button": BackButton
  },

  data() {
    return {
      title: "List of questions",
      icons: {
        add: PlusIcon,
      },
      currentPage: 1,
      perPage: 10,
      items: [],

    }
  },

  async mounted(): Promise<void> {
    await this.setQuestionaryQuestions();
    this.fetchUsersImages();
  },

  computed: {
    ...mapGetters("venueQuestionaryModule", {
      questionaryQuestions: "GET_QUESTIONARY_QUESTIONS",
      venueQuestionary: "GET_VENUE_QUESTIONARY",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    totalQuestions(): number {
      return this.questionaryQuestions.length;
    },
    questionsList(): Array<VenueData> {
      return this.questionaryQuestions.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    }
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      setQuestionaryQuestions: "FETCH_QUESTIONARY_QUESTIONS",
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    resetPagination(): void {
      this.currentPage = 1;
      this.fetchUsersImages()
    },

    fetchUsersImages(): void {
      if(!this.questionaryQuestions.length) return;
      for (const questionaryQuestion of this.questionaryQuestions){
        if(questionaryQuestion.czone_user_image_last_updated != 0){
          this.setImage({id: questionaryQuestion.czone_user_id, imageType: "clientzone_user", multi: true, venueQuestionaryImage: true});
        }
      }
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setQuestionaryQuestions();
          await this.setClientZoneUsers();
        }
      }
    }
  }
})
