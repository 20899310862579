











































































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "edit-question-modal" as string,

  props: {
    id: Number as PropType<number>,
    title: String as PropType<string>,
    text: String as PropType<string>,
    order: Number as PropType<number>,
    campaigns: Array as PropType<Array<any>>
  },

  data() {
    return {
      options: [
        {text: "survey 1", value: "survey1"},
        {text: "survey 2", value: "survey2"},
        {text: "survey 3", value: "survey3"},
        {text: "survey 4", value: "survey4"},
        {text: "survey 5", value: "survey5"},
      ],
      questionTitle: this.title,
      questionText: this.text
    }
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("venueQuestionaryModule", {
      questionLoader: "GET_QUESTION_LOADER"
    })
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      editQuestionaryQuestion: "EDIT_QUESTIONARY_QUESTION"
    }),
    async editQuestion(): Promise<void> {
      const payload: object = {
        title: this.questionTitle,
        text: this.questionText,
        order: this.order,
        venue_owner_id: this.companyId
      }

      await this.editQuestionaryQuestion({id: this.id, payload: payload});
      this.$bvModal.hide('EditQuestionPopUp' + this.id);
    }
  }
})
