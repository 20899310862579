


























































import Vue, {PropType} from "vue";
import { QuestionaryQuestionData, UserData, VenueQuestionaryData, VenueQuestionaryQuestionData } from "@/types";
import EditQuestionModal from "@/components/modals/EditQuestionModal.vue";
import DeleteItemConfirmModal from "@/components/modals/DeleteItemConfirmModal.vue";
import {mapActions} from "vuex";
import i18n from "@/i18n/i18n";
import moment from "moment";

export default Vue.extend({
  name: "question-table-item" as string,

  components: {
    "edit-question-modal": EditQuestionModal,
    "delete-item-confirm-modal": DeleteItemConfirmModal
  },

  props: {
    index: Number as PropType<number>,
    data: Object as PropType<QuestionaryQuestionData>,
    venueCampaigns: Array as PropType<Array<VenueQuestionaryData>>
  },

  data() {
    return {
      showQuestion: false,
      i18: i18n
    }
  },

  computed: {
    filteredCampaignsContainingQuestions(): Array<any> {
      const filteredCampaigns: Array<any> = [] as Array<any>;

      this.venueCampaigns.forEach((c: VenueQuestionaryData) => {
        c.questions.forEach((i: VenueQuestionaryQuestionData) => {
          if (i.id === this.data.id) {
            filteredCampaigns.push(c)
          }
        })
      })

      return filteredCampaigns;
    },

    insertVenueImage(): string {
      if (!this.data.image) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.data.image}`
    },

    createdAt(){
     return moment.unix(this.data.created_at).format("DD.MM.YYYY")
    },
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      deleteQuestionaryQuestion: "DELETE_QUESTIONARY_QUESTION"
    }),
    showQuestionText(): void {
      this.showQuestion = !this.showQuestion
    },

  }
})
