





















































import Vue from "vue";
import {mapActions, mapGetters} from "vuex";
import ValidationWarningModal from "@/components/modals/ValidationWarningModal.vue";


export default Vue.extend({
  name: "add-question-modal" as string,

  data() {
    return {
      questionTitle: "",
      questionText: "",
      randomId: Math.floor(Math.random() * 100000),
      validationMessage: ''
    }
  },
  components: {
    "validation-warning-modal": ValidationWarningModal,
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("venueQuestionaryModule", {
      questionLoader: "GET_QUESTION_LOADER"
    })
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      addQuestionaryQuestion: "ADD_QUESTIONARY_QUESTION"
    }),
    async addQuestion(): Promise<void> {
      if (!this.questionTitle.length && !this.questionText.length) {
        this.validationMessage = this.$t('All fields are required.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }
      if (!this.questionTitle.length) {
        this.validationMessage = this.$t('Question title is required.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }
      if (!this.questionText.length) {
        this.validationMessage = this.$t('Question description is required.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }
      const payload: object = {
        title: this.questionTitle,
        text: this.questionText,
        order: 1,
        venue_owner_id: this.companyId
      }

      await this.addQuestionaryQuestion(payload);
      this.$bvModal.hide('newQuestionPopUp');
      this.questionTitle = "";
      this.questionText = "";
    }
  }
})
