




























import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "validation-warning-modal" as string,

  props: {
    modalId: Number as PropType<number>,
    text: String as PropType<string>
  }
})
